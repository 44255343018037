import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const Hero = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.palette.primary};
  padding: ${({ hasOnePager }) => (hasOnePager ? '0px 15px' : '0px 15px 50px')};
  margin-top: ${({ hasImage }) => (hasImage ? '100px' : '0px')};
  margin-bottom: ${({ hasOnePager }) => (hasOnePager ? '130px' : '50px')};

  .hero-image {
    top: -100px;
    margin-bottom: -60px;
    max-height: 600px;
    max-width: ${({ theme }) => theme.layout.extraWideMaxWidth}px;
    margin-left: auto;
    margin-right: auto;
  }

  ${({ theme, hasImage, hasOnePager }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding-top: ${(hasImage ? '0px' : '120px')};
    padding-right: 32px;
    padding-left: 32px;
    padding-bottom: ${hasOnePager ? '0px' : '60px'};
    margin-top: ${hasImage ? '100px' : '0px'};
    margin-bottom: ${(hasOnePager ? '150px' : '60px')};


    .hero-image {
      top: -100px;
      margin-bottom: -65px;
      max-height: 400px;
      max-width: ${theme.layout.extraWideMaxWidth}px;
      margin-left: auto;
      margin-right: auto;
    }
  `)}

  ${({ theme, hasOnePager }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding-bottom: ${hasOnePager ? '0px' : '80px'};

    .hero-image {
      margin-bottom: -60px;
      max-height: 550px;
    }

    h1 {
      margin-bottom: 32px;
    }
  `)}

  ${({ theme, hasOnePager }) => createBreakpoint(theme.breakpoints.desktopMedium, css`
    padding-bottom: ${hasOnePager ? '0px' : '80px'};

    .hero-image {
      margin-bottom: -30px;
      max-height: 600px;
    }
  `)}
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.layout.globalMaxWidth}px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  padding: 0px 17px;
`;

export const OnePagerDownload = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-self: flex-end;
  margin-bottom: -90px;
  margin-top: 30px;
  transition: box-shadow 0.3s, transform 0.3s;

  a {
    padding: 40px 32px;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    background-color: ${({ theme }) => theme.palette.highlight};
    color: ${({ theme }) => theme.palette.primary};
    span {
      margin-right: 28px;
    }
    svg {
      margin-left: -22px;
      transform: rotate(90deg);
    }
  }

  &:hover {
    box-shadow: 0 2em 4rem rgba(0, 0, 0, .1);
    transform: translateY(-2%);

    a {
      background-color: ${({ theme }) => theme.palette.secondaryHighlight};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    max-width: 350px;

    a {
      padding: 40px 50px;
    }
  `)}
`;

export const PolicySection = styled.section`
  padding: 0px 32px;
  max-width: ${({ theme }) => theme.layout.globalMaxWidth}px;
  margin: 70px auto;

  h2 {
    margin-bottom: 32px;
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    h2 {
      margin-bottom: 45px;
    }
    margin: 96px auto;
  `)}
`;

export const PolicyEyebrow = styled.span`
  display: block;
  color: ${({ theme }) => theme.palette.primary};
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.6;
`;

export const PolicyTitle = styled.span`
  display: block;
  font-size: 44px;
  line-height: 1;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryHighlight};

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 60px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 80px;
  `)}
`;

export const PolicyContent = styled.div`
  padding: 32px 17px;
  background-color: ${({ theme }) => theme.palette.lighterPrimary};

  h3 {
    margin-top: 40px;

    &:first-of-type {
      margin-top: 0px;
    }

    a {
      font-weight: 700;
      display: inline;

      &:after {
        bottom: 5px;
      }

      &:hover {
        letter-spacing: 0em;
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    padding: 60px 80px;

    h3 {
      margin-top: 50px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 80px 96px;

    h3 {
      margin-top: 70px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
  `)}
`;

export const RecordContent = styled.div`
  padding: 32px 17px;
  background-color: ${({ theme }) => theme.palette.secondary};

  h3 {
    margin-top: 40px;

    &:first-of-type {
      margin-top: 0px;
    }
  }

  a {
    span, svg {
      display: inline;
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    h3 {
      margin-top: 50px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
    padding: 60px 80px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    h3 {
      margin-top: 70px;

      &:first-of-type {
        margin-top: 0px;
      }
    }
    padding: 80px 96px;
  `)}

`;
