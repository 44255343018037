import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Layout, Link } from '@components';
import { Header1 } from '@styles';
import { Arrow } from '@styles/icons';
import { getKeyValue, compileRichText, compileRecordList } from '@utils';
import {
  SMALL, XLARGE, LIGHT, DARK,
} from '@utils/vars';
import {
  Hero, Content, OnePagerDownload, PolicySection, PolicyEyebrow, PolicyTitle,
  PolicyContent, RecordContent,
} from './issue.styles';

const IssueTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    prioritiesTitle, prioritiesEyebrow, priorities, title, onePager, record,
    summary, heroImage, onePagerDownloadText, recordTitle, recordEyebrow,
    heroImageHorizontalPosition = '50', heroImageVerticalPosition = '50',
  } = contentEntry || {};

  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer, footerEmails,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      fullWidth
      navTheme={(heroImage && heroImage.fluid) ? LIGHT : DARK}
      doNotIndex={doNotIndex}
    >
      <div>
        <Hero hasImage={heroImage && heroImage.fluid} hasOnePager={onePager && onePager.file.url}>
          {heroImage && heroImage.fluid && (
            <Img
              alt={heroImage.description}
              fluid={heroImage.fluid}
              className="hero-image"
              imgStyle={{ objectPosition: `${heroImageHorizontalPosition}% ${heroImageVerticalPosition}%` }}
            />
          )}
          <Content>
            {title && (
              <Header1 textTheme="white" size={XLARGE}>
                {title}
              </Header1>
            )}
            {summary && (
              compileRichText({ textTheme: 'white', size: XLARGE, locale })(summary)
            )}
            {onePager && onePager.file.url && (
              <OnePagerDownload>
                <Link href={onePager.file.url}>
                  <span>{onePagerDownloadText || onePager.description}</span>
                  <Arrow strokeColor="primary" height="23px" width="26px" />
                </Link>
              </OnePagerDownload>
            )}
          </Content>
        </Hero>
        <PolicySection>
          {(prioritiesEyebrow || prioritiesTitle) && (
            <h2>
              {prioritiesEyebrow && (
                <PolicyEyebrow>
                  {prioritiesEyebrow}
                </PolicyEyebrow>
              )}
              {prioritiesTitle && (
                <PolicyTitle>
                  {prioritiesTitle}
                </PolicyTitle>
              )}
            </h2>
          )}
          {priorities && (
            <PolicyContent>
              {compileRichText({ textTheme: 'primary', size: SMALL, locale })(priorities)}
            </PolicyContent>
          )}
        </PolicySection>
        <PolicySection>
          {(recordEyebrow || recordTitle) && (
          <h2>
            {recordEyebrow && (
            <PolicyEyebrow>
              {recordEyebrow}
            </PolicyEyebrow>
            )}
            {recordTitle && (
            <PolicyTitle>
              {recordTitle}
            </PolicyTitle>
            )}
          </h2>
          )}
          {record && (
            <RecordContent>
              {compileRecordList({ textTheme: 'white' })(record)}
            </RecordContent>
          )}
        </PolicySection>
      </div>
    </Layout>
  );
};

export default IssueTemplate;

export const issuePageQuery = graphql`
  query IssueBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulKeyIssueArea {
          id
          title
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 1320) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          heroImageHorizontalPosition
          heroImageVerticalPosition
          prioritiesEyebrow
          prioritiesTitle
          priorities {
            raw
            references {
              ... on ContentfulPage {
                id
                pageUrl
                contentful_id
                internal {
                  type
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
            }
          }
          recordEyebrow
          recordTitle
          record {
            raw
            references {
              ... on ContentfulPage {
                id
                pageUrl
                contentful_id
                internal {
                  type
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
            }
          }
          summary {
            raw
            references {
              file {
                url
              }
              title
            }
          }
          onePagerDownloadText
          onePager {
            file {
              url
            }
            description
          }
          relatedPlans {
            page {
              pageUrl
            }
            title
            id
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
